import { MediaItem, SortOrder, SurveyMedia } from '@cpm/scanifly-shared-data';
import { LoadingIndicator } from 'components';
import { ZIP_MAX_FILE_SIZE } from 'components/ZipButton/constants';
import { openErrorNotification, openSuccessNotification } from 'components/ZipButton/helpers';
import breakpoints from 'helpers/constants/breakpoints';
import {
  ALL_PHOTOS_NAME,
  ALL_PHOTOS_URL_ENCODED,
  DRONE_IMAGES,
  DRONE_IMAGES_CATEGORY,
} from 'helpers/constants/categories';
import fontSizes from 'helpers/constants/fontSizes';
import zipMedia from 'helpers/utils/zipMedia';
import { kebabCase } from 'lodash';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectAllMedia } from 'state/slices/media';
import { getAllMedia } from 'state/slices/media/mediaThunks';
import { projectMediasRequested } from 'state/slices/mediasSlice';
import { projectRequested } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';
import styled from 'styled-components';
import { formatNameFromPath } from '../helpers';
import { Breadcrumbs } from './Breadcrumbs/Breadcrumbs';
import { DroneUploadsNotAvailable } from './DroneUploadsNotAvailable/DroneUploadsNotAvailable';
import { SorterDisplay } from './SorterDisplay/SorterDisplay';
import { ThumbnailGrid } from './ThumbnailGrid/ThumbnailGrid';
import { ToggleLegacyViewButton } from './ToggleLegacyViewButton/ToggleLegacyViewButton';
import { SortOptionTypes } from './types';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;

  @media (max-width: ${breakpoints.lg}) {
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;
  }
`;

const MainContainer = styled.div`
  margin: 1rem 2.5rem;
`;

const Title = styled.h2`
  @media screen and (max-width: ${breakpoints.xl}) {
    font-size: ${fontSizes.medium};
  }
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const AlbumView = ({
  lastViewedImageId,
  setLastViewedImageId,
}: {
  lastViewedImageId: string;
  setLastViewedImageId: Dispatch<SetStateAction<string>>;
}) => {
  const dispatch: AppDispatch = useDispatch();
  const { projectId, categoryName } = useParams<{ projectId: string; categoryName: string }>();
  const { project } = useSelector((state: RootState) => state.project);
  const { projectMedias, isProjectMediasLoading } = useSelector((state: RootState) => state.medias);
  const allMedias = useSelector((state: RootState) => selectAllMedia(state, projectId));
  const [selectedImages, setSelectedImages] = useState<Record<string, SurveyMedia | MediaItem>>({});
  const [albumToDisplay, setAlbumToDisplay] = useState<(SurveyMedia | MediaItem)[]>([]);
  const [isZipLoading, setIsZipLoading] = useState(false);
  const isAllPhotosCategorySelected =
    categoryName === ALL_PHOTOS_NAME || categoryName === ALL_PHOTOS_URL_ENCODED;

  const isDroneImagesCategorySelected =
    categoryName === DRONE_IMAGES || categoryName === DRONE_IMAGES_CATEGORY;

  const category = formatNameFromPath(categoryName ?? '');
  const selectedImageLength = Object.keys(selectedImages).length;
  const files = selectedImageLength > 0 ? Object.values(selectedImages) : albumToDisplay;
  const folderName = `${project?.name} ${categoryName}` || 'zip';

  useEffect(() => {
    if (projectId) {
      dispatch(projectRequested(projectId));
      if (isAllPhotosCategorySelected) {
        dispatch(
          getAllMedia({
            projectId,
            withFileExtension: false,
          })
        );
      } else {
        dispatch(
          projectMediasRequested({
            projectId,
            mediaCategoryName: category,
            withFileExtension: false,
          })
        );
      }
    }
  }, [category, dispatch, isAllPhotosCategorySelected, projectId]);

  useEffect(() => {
    if (isAllPhotosCategorySelected) {
      setAlbumToDisplay(allMedias);
    } else {
      setAlbumToDisplay(projectMedias);
    }
  }, [allMedias, isAllPhotosCategorySelected, projectMedias]);

  const handleSort = ({
    sortByField,
    order,
  }: {
    sortByField: SortOptionTypes;
    order: SortOrder;
  }) => {
    const sortedAlbum: (MediaItem | SurveyMedia)[] = [...albumToDisplay];
    const orderMultiplier = order === SortOrder.asc ? 1 : -1;
    if (sortByField === 'name') {
      sortedAlbum.sort(
        (a, b) => orderMultiplier * (a?.displayValue ?? '').localeCompare(b?.displayValue ?? '')
      );
      setAlbumToDisplay(sortedAlbum);
    }
  };

  const handleZip = useCallback(async () => {
    const processResult = (zipName: string | false) => {
      if (zipName) {
        openSuccessNotification(zipName);
      } else {
        openErrorNotification();
      }
      setIsZipLoading(false);
    };

    /**
     * Generates ZIP data
     * @returns zipName if successful, false if not
     */
    const generatedData = async ({
      mediaToZip,
      folderName,
    }: {
      mediaToZip: (SurveyMedia | MediaItem)[];
      folderName: string;
    }): Promise<string | false> => {
      const zipName = kebabCase(`scanifly ${folderName}`);
      const zipSize = files.reduce((totalSize, { size }) => totalSize + (size ?? 0), 0);
      let data;
      if (zipSize < ZIP_MAX_FILE_SIZE) {
        data = await zipMedia(mediaToZip, zipName);
      } else {
        let i = 0;
        let folder: (SurveyMedia | MediaItem)[], folderSize;
        /* eslint-disable no-await-in-loop */
        for (let j = 0; j < Math.ceil(zipSize / ZIP_MAX_FILE_SIZE); j++) {
          folder = [];
          folderSize = 0;
          while (folderSize < ZIP_MAX_FILE_SIZE && mediaToZip[i]) {
            folder.push(mediaToZip[i]);
            folderSize += mediaToZip[i].size ?? 0;
            i++;
          }
          // We would like to serialize zips as to not exceed 2GB
          data = await zipMedia(folder, zipName + '-' + (j + 1));
        } /* eslint-enable no-await-in-loop */
      }
      return data === true ? zipName : false;
    };

    const zipName = await generatedData({ mediaToZip: files, folderName });
    processResult(zipName);
  }, [files, folderName]);

  const handleDownloadClick = () => {
    setIsZipLoading(true);
    if (selectedImageLength === 1) {
      const checked = Object.values(selectedImages)[0];
      if (checked.imgUrl) {
        const a = document.createElement('a');
        a.href = checked.imgUrl;
        a.download = `${checked.originalFilenameWithoutExtension}${checked.originalFileExtension}`;
        a.click();
      }
      setIsZipLoading(false);
    } else {
      handleZip();
    }
  };

  return (
    <MainContainer>
      <Wrapper>
        <div>
          <Title>{project?.name}</Title>
          <Breadcrumbs category={category} projectId={projectId} />
        </div>
        <ToggleLegacyViewButton toLegacy={true} />
      </Wrapper>
      <SorterDisplay
        selectedImages={selectedImages}
        album={albumToDisplay}
        handleSort={handleSort}
        project={project}
        categoryName={category}
        isZipLoading={isZipLoading}
        setIsZipLoading={setIsZipLoading}
      />
      {albumToDisplay.length === 0 && isDroneImagesCategorySelected && !isProjectMediasLoading ? (
        <DroneUploadsNotAvailable />
      ) : (
        <ThumbnailGrid
          album={albumToDisplay}
          selectedImages={selectedImages}
          setSelectedImages={setSelectedImages}
          categoryName={category}
          handleDownload={handleDownloadClick}
          lastViewedImageId={lastViewedImageId}
          setLastViewedImageId={setLastViewedImageId}
        />
      )}
      {isProjectMediasLoading ? (
        <LoaderWrapper>
          <LoadingIndicator size="med" />
        </LoaderWrapper>
      ) : null}
    </MainContainer>
  );
};
