import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { nanoid } from '@reduxjs/toolkit';

import useHubSpot from 'helpers/hooks/useHubSpot';
import useLaunchDarkly from 'helpers/hooks/useLaunchDarkly';
import useUserpilot from 'helpers/hooks/useUserpilot';

import { IFrame } from './IFrame';
import './S3DWrapper.scss';

const S3DV3Wrapper = () => {
  useHubSpot();
  useUserpilot();
  useLaunchDarkly();

  const { projectId, designId } = useParams<{ projectId: string; designId: string }>();

  const searchParams = new URLSearchParams(window.location.search);
  const version = searchParams.get('version');
  const mode = searchParams.get('mode');
  const controls = searchParams.get('controls');

  const src = useMemo(() => {
    // Explicitly retrieving the `token` inside the useMemo because we don't want to reload the iFrame
    // if we refresh the user's token and cause them to lose their unsaved design progress.
    // IFrame component will use JS `postMessage` to give refreshed access token to S3D
    const token = localStorage.getItem('accessToken');
    let url = `${process.env.REACT_APP_S3D_URL}?ID=${projectId}&did=${designId}&token=${token}`;
    if (version) {
      url += `&version=${version}`;
    }
    if (mode) {
      url += `&mode=${mode}`;
    }
    if (controls) {
      url += `&controls=${controls}`;
    }
    url += `&${nanoid()}`;
    return url;
  }, [designId, projectId, version, mode, controls]);

  return (
    <div className="S3DWrapper">
      <IFrame src={src} />
    </div>
  );
};

export { S3DV3Wrapper };
