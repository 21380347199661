import { colors } from '@cpm/scanifly-shared-data';
import Dropdown from 'components/Dropdown';
import hexToRgbA from 'helpers/utils/hexToRgbA';
import { i18n } from 'helpers/utils/translations';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const integrationOptions = [
  'EcoFasten',
  'Energy ToolBase',
  'K2',
  'Pegasus',
  'SnapNrack',
  'Subcontractor Hub',
  'Sunraise Capital',
  'Unirac',
  'Zapier',
  i18n.t('AccessTokens.other'),
];

const Input = styled.input<{ isVisible: boolean }>`
  width: 100%;
  border: 1px solid #ccc;
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid ${colors.lightGray};
  margin-top: 1rem;

  ::placeholder {
    color: ${colors.midGray};
  }

  :focus {
    outline: none;
    border-color: ${colors.mainBlue};
    box-shadow: 0 0 0 2px ${hexToRgbA(colors.lightBlue, 0.5)};
  }
  display: ${(props) => (props.isVisible ? 'auto' : 'none')};
`;

type NewTokenFormProps = {
  onChange: (values: { selectedOption: string | undefined; inputValue: string }) => void;
};

export const NewTokenForm = ({ onChange }: NewTokenFormProps) => {
  const [selectedOption, setSelectedOption] = useState<string | undefined>();
  const [inputValue, setInputValue] = useState('');
  const { t } = useTranslation();

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setInputValue(e.target.value);
      onChange({ selectedOption, inputValue: e.target.value });
    },
    [selectedOption, onChange]
  );

  const onSelectChange = useCallback(
    (selectedOption: string) => {
      setSelectedOption(selectedOption);
      onChange({ selectedOption, inputValue });
    },
    [inputValue, onChange]
  );

  const isInputVisible = selectedOption === t('AccessTokens.other');

  return (
    <div>
      <Dropdown
        options={integrationOptions}
        value={selectedOption ?? ''}
        onChange={onSelectChange}
        displayedValue={selectedOption ?? t('AccessTokens.select')}
        width={100}
      />
      <Input
        value={inputValue}
        onChange={onInputChange}
        placeholder={t('AccessTokens.enterName')}
        isVisible={isInputVisible}
      />
    </div>
  );
};
