import React, { ChangeEvent } from 'react';

import styled from 'styled-components';

import colors from 'helpers/constants/colors';

interface ToggleProps {
  id: string;
  label: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  disabled?: boolean;
}
const Switch = styled.div`
  position: relative;
  width: 3.6rem;
  height: 2rem;
  background: #b3b3b3;
  border-radius: 2rem;
  padding: 0.3rem;
  transition: 300ms all;

  &:before {
    transition: 300ms all;
    content: '';
    position: absolute;
    width: 1.65rem;
    height: 1.65rem;
    border-radius: 2rem;
    top: 50%;
    left: 1.4rem;
    background: white;
    transform: translate(-1.25rem, -50%);
  }
`;

const ToggleContainer = styled.label`
  display: flex;
  align-items: center;
`;

const Input = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  position: absolute;

  &:checked + ${Switch} {
    background: ${colors.mainBlue};

    &:before {
      transform: translate(0.4rem, -50%);
    }
  }
`;

const Label = styled.span`
  margin-left: 0.3rem;
  text-align: center;
  color: ${colors.black};
`;

const Toggle: React.FC<ToggleProps> = ({
  id,
  label,
  onChange,
  checked,
  disabled = false,
}: ToggleProps) => {
  return (
    <ToggleContainer htmlFor={id}>
      <Input id={id} onChange={onChange} defaultChecked={checked} disabled={disabled} />
      <Switch />
      <Label>{label}</Label>
    </ToggleContainer>
  );
};
export default Toggle;
