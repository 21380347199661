import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { FEATURE_LIST, getFeatureAvailability, PROJECT_TYPES } from '@cpm/scanifly-shared-data';
import cn from 'classnames';
import { kebabCase } from 'lodash-es';
import { thresholdDateForSmallProjects } from 'screens/Albums/ProjectCategory/DroneDataScore/constants';
import { ProjectDesignData } from 'types';

import { RootState } from 'state/store';

import { SidebarTag } from 'components';
import { TagTypes } from 'components/SidebarTag/constants';

import { ACCESS } from 'helpers/constants/access';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import usePermissions from 'helpers/hooks/usePermissions';
import useS3D from 'helpers/hooks/useS3D';
import { formatPercent } from 'helpers/utils/formatPercent';

import CommentIcon from 'assets/comment-icon.svg';
import DesignsIcon from 'assets/designs-icon.svg';
import MaintenanceIcon from 'assets/maintenance.svg';
import ProjectTitleIcon from 'assets/project-title-icon.svg';
import SiteDataIcon from 'assets/site-data-icon.svg';

import { MenuItemType } from 'screens/Sidebar/types';
import { ALL_STEPS, DESIGN_SERVICES_MENU } from '../../constants';
import { getMenuRoute, getTooltipTitle, isProjectEligibleForDesignOrder } from '../../helpers';
import InlineMenu from '../InlineMenu';
import './ProjectMenuSidebar.scss';

type ProjectMenuSidebarProps = {
  isScaniflyAdminView: boolean;
  isNewProjectView: boolean;
  isDraftView: boolean;
  isCollapsed: boolean;
};

// I fear I am making a god component here but it seemed like the only sane way to keep code duplication
// to a minimum when there are 4 unique routes for each project step.
const ProjectMenuSidebar = ({
  isScaniflyAdminView,
  isNewProjectView,
  isDraftView,
  isCollapsed,
}: ProjectMenuSidebarProps) => {
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const isLegacy = searchParams.get('legacy') !== '0';
  const { project } = useSelector((state: RootState) => state.project);
  const { projectDesigns: reduxProjectDesigns } = useSelector(
    (state: RootState) => state.projectDesigns
  );
  const { company } = useSelector((state: RootState) => state.company);
  const { isScaniflyAdmin, isSimpleDesignOrSalesManager } = usePermissions();
  const maintenanceReportAccess = useFeatureToggle(ACCESS.MAINTENANCE_REPORT);
  const droneDataScoreAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.DRONE_DATA_SCORE,
    company?.pricingTier
  );
  const designServicesAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.DESIGN_SERVICES,
    company?.pricingTier
  );
  const checklistAccess = getFeatureAvailability(
    isScaniflyAdmin,
    FEATURE_LIST.CHECKLISTS,
    company?.pricingTier
  );
  const s3d = useS3D();

  const isAlbumNotDroneImages = useMemo(() => {
    const match = /\/albums\/(?!.*Drone-Images$).*$/.exec(pathname ?? '');
    return match && match.length > 0;
  }, [pathname]);

  // TODO: Remove this janky work around. Ideally, when the current project switches
  // we would request new designs from the server.
  const [projectDesigns, setProjectDesigns] = useState<ProjectDesignData[]>([]);
  useEffect(() => {
    const filteredDesigns: ProjectDesignData[] = [];
    if (reduxProjectDesigns && reduxProjectDesigns?.length > 0) {
      reduxProjectDesigns.forEach((design) => {
        if (design?.projectId === project?.id) {
          filteredDesigns.push(design);
        }
      });
    }
    setProjectDesigns(filteredDesigns);
  }, [reduxProjectDesigns, project, setProjectDesigns]);

  const menuHeaderKey = 'sidebar-submenu-header';
  const menuChildKey = 'sidebar-submenu-child';

  /**
   *  Project Name Section
   *    Project Overview
   *    Consumption Data
   *    Scanifly Info
   *    Project Info
   */
  const projectOverview = {
    key: `${menuHeaderKey}-${kebabCase(ALL_STEPS.PROJECT_OVERVIEW)}`,
    icon: (
      <img alt="" src={ProjectTitleIcon} className="Sidebar-Icon Sidebar-Icon--Project-Title" />
    ),
    children: [
      {
        key: `${menuChildKey}-${kebabCase(ALL_STEPS.CUSTOMER_INFO)}`,
        label: ALL_STEPS.CUSTOMER_INFO,
        link: getMenuRoute(ALL_STEPS.CUSTOMER_INFO, isScaniflyAdminView, isDraftView, project?.id),
      },
      {
        key: `${menuChildKey}-${kebabCase(ALL_STEPS.CONSUMPTION_DATA)}`,
        label: ALL_STEPS.CONSUMPTION_DATA,
        link: getMenuRoute(
          ALL_STEPS.CONSUMPTION_DATA,
          isScaniflyAdminView,
          isDraftView,
          project?.id
        ),
      },
    ],
    label: 'Project Overview',
    disabled: isNewProjectView,
    link: getMenuRoute(ALL_STEPS.PROJECT_OVERVIEW, isScaniflyAdminView, isDraftView, project?.id),
  };

  if (isScaniflyAdminView) {
    projectOverview.children.push({
      key: `${menuChildKey}-${kebabCase(ALL_STEPS.SCANIFLY_INFO)}`,
      label: ALL_STEPS.SCANIFLY_INFO,
      link: getMenuRoute(ALL_STEPS.SCANIFLY_INFO, isScaniflyAdminView, isDraftView, project?.id),
    });
  }

  if (!isSimpleDesignOrSalesManager) {
    projectOverview.children.push({
      key: `${menuChildKey}-${kebabCase(ALL_STEPS.PROJECT_INFO)}`,
      label: ALL_STEPS.PROJECT_INFO,
      link: getMenuRoute(ALL_STEPS.PROJECT_INFO, isScaniflyAdminView, isDraftView, project?.id),
    });
  }

  const projectCompletedDate = project?.completedDate
    ? new Date(project.completedDate).getTime()
    : 0;

  const isProjectCompletedAfterThresholdDate =
    projectCompletedDate &&
    (project?.type === PROJECT_TYPES.LARGE
      ? false
      : projectCompletedDate > thresholdDateForSmallProjects ||
        (projectCompletedDate <= thresholdDateForSmallProjects &&
          Number(project?.droneData?.droneDataScore)));

  /**
   *  Site Data Section
   *    Drone Images
   *    Albums
   *    Checklists
   */

  const siteDataChildren = [
    {
      key: `${menuChildKey}-${kebabCase(ALL_STEPS.DRONE_IMAGES)}`,
      label: ALL_STEPS.DRONE_IMAGES,
      link: getMenuRoute(ALL_STEPS.DRONE_IMAGES, isScaniflyAdminView, isDraftView, project?.id),
      isDisabled: isAlbumNotDroneImages && isLegacy,
      rightPlacement:
        droneDataScoreAccess &&
        project?.droneData?.droneDataScore &&
        isProjectCompletedAfterThresholdDate ? (
          <SidebarTag
            text={formatPercent(Number(project?.droneData?.droneDataScore))}
            type={TagTypes.score}
          />
        ) : null,
    },
    {
      key: `${menuChildKey}-${kebabCase(ALL_STEPS.ALBUMS)}`,
      label: ALL_STEPS.ALBUMS,
      link: getMenuRoute(ALL_STEPS.ALBUMS, isScaniflyAdminView, isDraftView, project?.id),
      isDisabled: false,
      rightPlacement: null,
    },
  ];

  const checklistItem = {
    key: `${menuChildKey}-${kebabCase(ALL_STEPS.CHECKLISTS)}`,
    label: ALL_STEPS.CHECKLISTS,
    displayUpgradeMessage: !checklistAccess,
    link: getMenuRoute(ALL_STEPS.CHECKLISTS, isScaniflyAdminView, isDraftView, project?.id),
    isDisabled: false,
    rightPlacement: null,
  };
  checklistAccess && siteDataChildren.push(checklistItem);

  const siteData = {
    key: `${menuHeaderKey}-${kebabCase(ALL_STEPS.SITE_DATA)}`,
    icon: <img alt="" src={SiteDataIcon} className="Sidebar-Icon Sidebar-Icon--Site-Data" />,
    children: siteDataChildren,
    label: ALL_STEPS.SITE_DATA,
    disabled: isNewProjectView,
    link: getMenuRoute(ALL_STEPS.SITE_DATA, isScaniflyAdminView, isDraftView, project?.id),
  };

  /**
   *  Designs Section
   *    {Any designs that exist}
   */
  const designs: {
    key: string;
    icon: JSX.Element;
    children: {
      key: string;
      label: string;
      link: string;
      rightPlacement?: JSX.Element;
      isDisabled?: boolean;
      displayUpgradeMessage?: boolean;
      tooltipTitle?: JSX.Element | string;
    }[];
    label: string;
    link: string;
    disabled: boolean;
  } = {
    key: `${menuHeaderKey}-${kebabCase(ALL_STEPS.DESIGNS)}`,
    icon: <img alt="" src={DesignsIcon} className="Sidebar-Icon Sidebar-Icon--Designs" />,
    children: [],
    label: ALL_STEPS.DESIGNS,
    link: getMenuRoute(ALL_STEPS.MODELS, isScaniflyAdminView, isDraftView, project?.id),
    disabled: isNewProjectView,
  };

  if (projectDesigns && projectDesigns.length > 0) {
    for (const projectDesign of projectDesigns) {
      designs.children.push({
        key: `${menuChildKey}-Design-${kebabCase(projectDesign?.name)}`,
        label: projectDesign.name,
        link: s3d(project?.id ?? '', projectDesign?.id ?? ''),
      });
    }
  }

  const eligibleForDesignOrder = isProjectEligibleForDesignOrder(project?.status);

  if (eligibleForDesignOrder && !isSimpleDesignOrSalesManager) {
    Object.values(DESIGN_SERVICES_MENU).forEach((title) => {
      designs.children.push({
        key: `${menuChildKey}-${kebabCase(title)}`,
        label: title,
        link: getMenuRoute(title, isScaniflyAdminView, isDraftView, project?.id),
        displayUpgradeMessage: !designServicesAccess,
        tooltipTitle: getTooltipTitle({
          isBasicTier: !designServicesAccess,
          title,
        }),
      });
    });
  }

  /**
   *  Comments Section
   */
  const notes = {
    key: `${menuHeaderKey}-${kebabCase(ALL_STEPS.COMMENTS)}`,
    icon: <img alt="" src={CommentIcon} className="Sidebar-Icon Sidebar-Icon--Notes" />,
    label: ALL_STEPS.COMMENTS,
    link: getMenuRoute(ALL_STEPS.COMMENTS, isScaniflyAdminView, isDraftView, project?.id),
    disabled: isNewProjectView,
  };

  /**
   *  Maintenance Section
   */
  const maintenance = {
    key: `${menuHeaderKey}-${kebabCase(ALL_STEPS.MAINTENANCE)}`,
    icon: <img alt="" src={MaintenanceIcon} className="Sidebar-Icon Sidebar-Icon--Maintenance" />,
    label: ALL_STEPS.MAINTENANCE,
    link: getMenuRoute(ALL_STEPS.MAINTENANCE, isScaniflyAdminView, isDraftView, project?.id),
    disabled: false,
  };

  const menuItems: MenuItemType[] = [projectOverview, siteData, designs, notes];

  if (maintenanceReportAccess && !isSimpleDesignOrSalesManager) {
    menuItems.splice(3, 0, maintenance);
  }

  return (
    <div
      className={cn('Sidebar-Project-Menu-Container', {
        'Sidebar-Project-Menu-Container--collapsed': isCollapsed,
      })}
      data-testid="project-menu-sidebar"
    >
      <InlineMenu menuItems={menuItems} isCollapsed={isCollapsed} />
    </div>
  );
};

export default ProjectMenuSidebar;
