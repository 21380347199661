import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { AddButton, EditFieldText } from 'screens/ChecklistTemplate/Editor/common';

import styled from 'styled-components';

import { Button } from 'components';

import colors from 'helpers/constants/colors';

import { ReactComponent as DownIcon } from 'assets/icons/chevron-down.svg';
import { ReactComponent as UpIcon } from 'assets/icons/chevron-up.svg';

interface OptionsFieldArrayProps {
  options: string[];
  setOptions: (newOptions: string[]) => void;
}

const Container = styled.div`
  margin-bottom: 1rem;
`;

const Label = styled.label`
  font-weight: bold;
`;

const ListContainer = styled.div``;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0.5rem 0;
`;

const TextWrapper = styled.div`
  flex-grow: 1;
`;

const IconContainer = styled.button`
  border: none;
  padding: 0;
  background: none;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledUpIcon = styled(UpIcon)`
  vertical-align: middle;
  height: 1.5rem;
  width: 1.5rem;
  ${IconContainer}: hover & {
    fill: ${colors.mainBlue};
  }
`;

const StyledDownIcon = styled(DownIcon)`
  vertical-align: middle;
  height: 1.5rem;
  width: 1.5rem;
  ${IconContainer}: hover & {
    fill: ${colors.mainBlue};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-left: 1rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & button {
    margin: 0 0.5rem;
  }
`;

export const OptionsFieldArray: React.FC<OptionsFieldArrayProps> = ({
  options,
  setOptions,
}: OptionsFieldArrayProps) => {
  const { t } = useTranslation();
  const handleAddOption = useCallback(() => {
    setOptions([...options, '']);
  }, [setOptions, options]);

  const handleRemoveOption = useCallback(
    (index: number) => {
      const updatedOptions = [...options];
      updatedOptions.splice(index, 1);
      setOptions(updatedOptions);
    },
    [setOptions, options]
  );

  const moveOptionUp = useCallback(
    (e: any, index: number) => {
      e.preventDefault();
      if (index <= 0) {
        return;
      }
      const tempItem = options[index];
      const tempOptions = [...options];
      tempOptions[index] = tempOptions[index - 1];
      tempOptions[index - 1] = tempItem;
      setOptions(tempOptions);
    },
    [options, setOptions]
  );
  const moveOptionDown = useCallback(
    (e: any, index: number) => {
      e.preventDefault();
      if (index + 1 >= options.length) {
        return;
      }
      const tempItem = options[index];
      const tempOptions = [...options];
      tempOptions[index] = tempOptions[index + 1];
      tempOptions[index + 1] = tempItem;
      setOptions(tempOptions);
    },
    [options, setOptions]
  );

  return (
    <Container>
      <Label>{t('Checklists.options')}:</Label>
      <ListContainer>
        {options.map((option: string, index: number) => (
          <OptionWrapper key={index}>
            <TextWrapper>
              <EditFieldText
                id={`option-${index}`}
                onFocus={() => {
                  if (options.length === 1 && options[0] === 'example option') {
                    const updatedOptions = [...options];
                    updatedOptions[index] = '';
                    setOptions(updatedOptions);
                  }
                }}
                onChange={(e) => {
                  const updatedOptions = [...options];
                  updatedOptions[index] = e.target.value;
                  setOptions(updatedOptions);
                }}
                onBlur={(e) => {
                  if (!e.target.value.trim()) {
                    const updatedOptions = [...options];
                    updatedOptions[index] = 'example option';
                    setOptions(updatedOptions);
                  }
                }}
                value={option}
              />
            </TextWrapper>
            <ButtonContainer>
              <IconContainer onClick={(e) => moveOptionUp(e, index)}>
                <StyledUpIcon />
              </IconContainer>
              <IconContainer onClick={(e) => moveOptionDown(e, index)}>
                <StyledDownIcon />
              </IconContainer>
              <Button
                onClick={() => handleRemoveOption(index)}
                width="auto"
                icon="delete"
                padding="0 0 0 1rem"
                iconSize="2rem"
                color={colors.red}
              />
            </ButtonContainer>
          </OptionWrapper>
        ))}
        <AddButton onClick={handleAddOption} />
      </ListContainer>
    </Container>
  );
};
