import styled from 'styled-components';

import { UploadThumbnail } from 'components';
import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';

type FieldWrapperProps = {
  conditional?: boolean;
};

export const ButtonWrapper = styled.div`
  opacity: 0;
`;

export const FieldWrapper = styled.div<FieldWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-bottom: 2rem;
  box-shadow: ${(props) => (props.conditional ? `-30px -10px 0 -16px ${colors.mainBlue}` : ``)};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem 0;
`;

export const RowNoMargin = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PreDescription = styled.div`
  font-weight: lighter;
  font-style: italic;
`;

export const Label = styled.div`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80%;

  @media (max-width: ${breakpoints.xxl}) {
    max-width: 51rem;
  }
  @media (max-width: ${breakpoints.xl}) {
    width: 30rem;
  }
`;

export const LabelLight = styled.div`
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${colors.labelGray};
  max-width: 80%;

  @media (max-width: ${breakpoints.xxl}) {
    max-width: 51rem;
  }
  @media (max-width: ${breakpoints.xl}) {
    width: 30rem;
  }
`;

export const Description = styled.div`
  font-weight: lighter;
  font-style: italic;

  @media (max-width: ${breakpoints.xl}) {
    width: 30rem;
  }
`;

export const FieldOptions = styled.ul`
  list-style-type: circle;
`;

export const FieldOptionsItem = styled.li``;

export const ValueType = styled.div`
  border: 1px solid ${colors.gray};
  padding: 0.2rem;
  align-self: flex-start;
`;

export const PostDescription = styled.div`
  font-weight: lighter;
  font-style: italic;
`;

type SpacerProps = {
  height?: number;
};

export const Spacer = styled.div<SpacerProps>`
  height: ${({ height }) => (height ? height + 'rem' : '1rem')};
`;

export const StyledTextInput = styled.input`
  margin: 1.5rem 0;
  padding: 0.5rem 2rem;
  border: 1px solid ${colors.lightGray};
  border-radius: 0.5rem;
  font-size: ${fontSizes.mediumSmall};
`;

//IdDisplay components

export const Container = styled.label`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
`;

export const Column = styled.div`
  margin-left: 2rem;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const IdLabel = styled.span`
  margin-right: 1rem;
  font-weight: bold;
  flex: 1;
`;

export const EditInput = styled.input.attrs({ type: 'text' })`
  padding: 0.5rem;
  margin: 0.5rem 0 0 0;
  border: 1px solid ${colors.neutralGray};
  border-radius: 0.5rem;
  width: 100%;
  min-width: 360px;
  flex: 5;
`;

export const TooltipText = styled.span`
  visibility: hidden;
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 36%;
  opacity: 0;
  left: 25%;
  transition: opacity 0.3s;
  white-space: nowrap;

  ${Container}:hover & {
    visibility: visible;
    opacity: 0.8;
  }
`;

export const ConditionalLabel = styled.label`
  margin-right: 1rem;
  margin-top: 0.5rem;
  font-weight: bold;
`;

export const ConditionalRow = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem;
  padding: 0.5rem 2rem;
`;

export const MediaContainer = styled.div`
  max-width: 500px;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;

  span {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  img {
    margin-bottom: 1rem;
  }
`;

export const MediaThumbnail = styled(UploadThumbnail)`
  height: 10rem;
  width: 10rem;
  border: solid;
  border-color: ${colors.midGray};
`;

export const PlaceHolderThumbnail = styled.div`
  height: 10rem;
  width: 10rem;
  border: solid;
  border-color: ${colors.midGray};
  background-color: ${colors.darkGrayOpacity};
`;

export const StyledThumbnailContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
