import border from 'helpers/constants/borderRadius';
import breakpoints from 'helpers/constants/breakpoints';
import colors from 'helpers/constants/colors';
import font from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import styled, { css } from 'styled-components';

const blueButtonStyles = css`
  background-color: ${colors.mainBlue};
  color: ${colors.white};

  &:hover,
  &:active,
  &:focus {
    background-color: ${colors.mainBlue};
    color: ${colors.white};
  }

  svg {
    margin-right: 8px;
    path {
      fill: ${colors.white};
    }
  }
`;

const AccessTokenStyle = styled.div`
  .AccessToken {
    &-NestedTable {
      height: 100%;
      background: ${colors.white};
      margin: 3rem;
      box-shadow:
        2rem 2rem 2rem ${colors.black}0F,
        -0.5rem -2rem 2rem ${colors.black}0F;

      .ant-table {
        background: ${colors.white};
        z-index: 1;
        margin-left: 0 !important;
      }

      .ant-table-row {
        background: ${colors.white};
      }

      .Button {
        &--Icon:not(.ScaniflyAdmin-DeleteBtn) {
          width: 4rem;
          padding: 0.85rem;

          svg path {
            fill: ${colors.darkGray};
          }

          &:hover {
            svg path {
              fill: ${colors.mainBlue};
            }
          }
        }

        &--White:not(.Button--Icon) {
          svg {
            margin-right: 5px;

            path {
              stroke: ${colors.darkGray};
            }
          }

          &:hover,
          &:focus,
          &:active {
            svg path {
              stroke: ${colors.mainBlue};
            }
          }

          &:disabled {
            svg path {
              stroke: ${colors.midGray};
            }
          }
        }
      }

      &--ActionsContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
      }
    }

    &-Button--Red {
      display: flex;
      align-items: center;
      border-radius: 1rem !important;
      background-color: ${colors.red} !important;
      color: ${colors.white} !important;
      border: none !important;
    }
    &-TokenWrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    &-Wrapper {
      padding: 0 3.2rem;
    }
    &-NoAccess {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 20rem;
      p {
        color: ${colors.labelGray};
        font-size: ${font.medium};
      }
    }
    &-SubHeader {
      display: flex;
      justify-content: space-between;
      margin: 3.4rem 0;

      p {
        margin: 0.65rem 3.5rem 0 2rem;
        color: ${colors.labelGray};
        font-size: ${font.medium};
      }

      & .Button {
        &--Blue {
          svg {
            margin-right: 8px;
            path {
              fill: ${colors.white};
            }
          }

          margin: 0 1rem;
        }

        &--White {
          margin: 0 1rem;
        }
      }

      @media only screen and (max-width: ${breakpoints.xl}) {
        flex-wrap: wrap;
        justify-content: flex-end;
        row-gap: 2rem;
      }

      @media only screen and (max-width: ${breakpoints.md}) {
        p {
          display: none;
        }
      }
    }
    &-Search {
      flex-grow: 1;
      margin: 0 0.5rem 0 2rem;
      height: 3.8rem;

      &-prefix {
        width: 1.7rem;
        margin-right: 1.2rem;
      }
      .ant-input {
        font-size: ${font.medium};
        margin-left: 0.5rem;
      }

      @media only screen and (max-width: ${breakpoints.lg}) {
        width: 50%;
      }
      @media only screen and (max-width: ${breakpoints.sm}) {
        width: 30%;
      }
    }

    &--Blue {
      ${blueButtonStyles};
      border: 0.1rem solid ${colors.neutralGray};
      margin: 0 1rem;

      &:hover {
        opacity: 0.85;
      }

      &:disabled {
        background-color: ${colors.lightGray};
        border: 0.1rem solid ${colors.neutralGray};
        color: ${colors.white};
      }
    }

    &-Table {
      overflow: auto;
      background: ${colors.white};
      margin: 0.5rem 0;
      border-radius: ${border.borderRadiusXs};
      margin-bottom: 7rem;

      .Tag {
        min-height: 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
      }

      .ant-pagination {
        margin-right: 2.55rem;
        margin-left: 2.55rem;
        position: fixed;
        bottom: 0.5rem;
        z-index: 0;
        background: ${colors.white};
        &-options {
          display: none;
        }
      }

      th.ant-table-row-expand-icon-cell,
      td.ant-table-row-expand-icon-cell {
        width: 6rem;
      }

      th.ant-table-cell-scrollbar,
      td.ant-table-cell-scrollbar {
        display: none;
      }

      tr.ant-table-expanded-row-level-1 {
        padding: 2rem !important;
      }

      .ant-table-expanded-row-level-1 > .ant-table-cell {
        padding: 0;
      }

      .ant-table,
      .ant-table-cell-fix-right,
      .ant-table-cell-fix-left {
        background: ${colors.white};
        z-index: 1;
      }

      td.ant-table-cell-fix-right,
      td.ant-table-cell-fix-left {
        font-weight: ${fontWeights.semiBold};
      }

      .ant-table-row {
        background: ${colors.white};
      }

      .ant-table-column-sorters {
        display: flex;
        justify-content: space-between;
      }

      .Button {
        &--Icon:not(.EditDeleteButton) {
          width: 4rem;
          padding: 0.85rem;

          svg path {
            fill: ${colors.darkGray};
          }

          &:hover,
          &:focus,
          &:active {
            svg path {
              fill: ${colors.mainBlue};
            }
          }
        }

        &--White {
          svg {
            margin-right: 5px;
            path {
              fill: ${colors.darkGray};
            }
          }

          &:hover,
          &:focus,
          &:active {
            svg path {
              fill: ${colors.mainBlue};
            }
          }

          &:disabled {
            svg path {
              fill: ${colors.midGray};
            }
          }
        }
      }

      label {
        margin-bottom: -0.15rem;
      }
    }
    &-ButtonWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &-CopyIcon {
        margin-left: 8px;
        font-size: 1.5rem;
        cursor: pointer;

        svg path {
          fill: ${colors.darkGray};
        }

        &:hover {
          svg path {
            fill: ${colors.mainBlue};
          }
        }
      }
    }
    &-Spinner {
      margin-bottom: 2.9rem;
    }
    &-Error {
      &-Visible {
        display: block;
        border: 1px solid ${colors.red};
        padding: 1rem;
        border-radius: ${border.borderRadiusXs};
        background: ${colors.redOpacity};
        margin-top: 1rem;
        color: ${colors.red};
      }

      &-NotVisible {
        display: none !important;
      }
    }

    &-GenerateTokenModal {
      &-ContentWrapper {
        width: 50rem;
        max-width: 100%;
      }
      &-Description {
        font-size: ${font.small};
        margin: 1.6rem 0 2rem 0;
      }

      &-info {
        font-size: 1.3rem;
        font-style: italic;
        margin: 1.6rem 0 3rem 0;
        color: ${colors.labelGray};
      }

      &-TokenText {
        letter-spacing: 0.025rem;

        &:hover {
          cursor: pointer;
        }
      }

      &-Token {
        display: flex;
        align-items: center;
        background: ${colors.lightGray}99;
        padding: 1.25rem 1.25rem 1.25rem 2rem;
        margin-bottom: 1.5rem;
        border-radius: ${border.borderRadiusSm};
        font-family: monospace;
        box-shadow: 0.15rem 0.15rem 0 rgba(0, 0, 0, 0.2);

        &:hover {
          background: ${colors.lightGray}B3;
        }
      }

      &-SelectWrapper {
        margin: 2.5rem 0 3rem 0;
      }
      &-Buttons {
        &-Wrapper {
          display: flex;
          justify-content: flex-end;

          > :first-child {
            margin-right: 1.6rem;
          }

          @media only screen and (max-width: ${breakpoints.xs}) {
            justify-content: center;
          }
        }
      }
      &-Dialogue {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      }
    }
  }
  #TokenDeleteBtn {
    height: 3.2rem;
    padding: 0 1rem;
  }
`;
export default AccessTokenStyle;
