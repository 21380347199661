import { Thumbnail as StandardThumbnail } from '@cpm/scanifly-shared-components';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { MediaItem, SurveyMedia } from '@cpm/scanifly-shared-data';
import { projectCategoryAlbumPreviewerRoute } from 'helpers/constants/routes';
import { KebabMenu } from '../KebabMenu/KebabMenu';
import { KebabMenuContent } from '../KebabMenuContent/KebabMenuContent';

export const Thumbnail = ({
  image,
  selectedImages,
  handleThumbnailCheckboxClick,
  handleClick,
  setOpenMiniMenuId,
  openMiniMenuId,
  toggleDeleteModal,
  handleDownload,
  lastViewedImageId,
  setLastViewedImageId,
}: {
  image: SurveyMedia | MediaItem;
  selectedImages: Record<string, SurveyMedia | MediaItem>;
  handleThumbnailCheckboxClick: (
    img: SurveyMedia | MediaItem,
    e?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  handleClick: (
    image: SurveyMedia | MediaItem,
    e?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => void;
  setOpenMiniMenuId: React.Dispatch<React.SetStateAction<string | null>>;
  openMiniMenuId: string | null;
  toggleDeleteModal: () => void;
  handleDownload?: () => void;
  lastViewedImageId: string;
  setLastViewedImageId: Dispatch<SetStateAction<string>>;
}) => {
  const isActive = Boolean(selectedImages[image.id]);
  const thumbnailRef = useRef<null | HTMLDivElement>(null);

  const handleKebabMenuClick = useCallback(
    (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (!isActive) {
        handleClick(image, e);
      }
    },
    [handleClick, image, isActive]
  );
  const history = useHistory();
  const params = useParams<{ projectId: string; categoryName: string }>();

  const onDoubleClick = () => {
    const query = new URLSearchParams({
      preview: image.id,
      legacy: '0',
    });
    setLastViewedImageId(image.id);
    history.push(
      `${projectCategoryAlbumPreviewerRoute(params.projectId, params.categoryName)}?${query.toString()}`
    );
  };

  // Scroll the selected thumbnail into view
  useEffect(() => {
    if (thumbnailRef.current && image.id === lastViewedImageId) {
      thumbnailRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [image.id, lastViewedImageId]);

  return (
    <StandardThumbnail
      ref={thumbnailRef}
      kebabMenu={
        <KebabMenu
          content={
            <KebabMenuContent
              isActive={isActive}
              selectedImages={selectedImages}
              image={image}
              handleDownload={handleDownload}
              toggleDeleteModal={toggleDeleteModal}
              setOpenMiniMenuId={setOpenMiniMenuId}
            />
          }
          isActive={isActive}
          setOpenMiniMenuId={setOpenMiniMenuId}
          openMiniMenuId={openMiniMenuId}
          onClick={handleKebabMenuClick}
          imageId={image.id}
        />
      }
      handleThumbnailCheckboxClick={handleThumbnailCheckboxClick}
      isActive={isActive}
      isAlbumView={true}
      image={image}
      handleClick={(image, e) => handleClick(image as MediaItem, e)}
      handleDoubleClick={onDoubleClick}
      withTooltip={true}
    />
  );
};
